import React, { Component } from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout/layout"
import RichText from "../components/Modules/RichText/richText"
import Wrapper from "../components/Layout/Wrapper/wrapper"
import { JumbotronSimple } from "../components/Layout/Jumbotron/jumbotron"
//import SEO from "../components/Modules/SEO/seo";
//import { checkUtmParams } from "../services/sem/checkUtmParams";
import {Helmet} from "react-helmet";

class LegalTemplate extends Component {
/*    componentDidMount() {
        checkUtmParams();
    }*/

    render() {
        const page = this.props.data.contentfulLegal
        const site = this.props.data.site
        return (
            <Layout hideMenuButton={false}>
                <Helmet>
                    <title>{page.title}</title>
                    <meta name="description" content={page.metadescription} />
                </Helmet>
                <div>
                    <JumbotronSimple legal static title={page.title}/>
                    <Wrapper classNames={`legal`}>
                        <RichText content={page.content.json}/>
                    </Wrapper>
                </div>
            </Layout>
        )
    }
}

export default LegalTemplate

export const pageQuery = graphql`
    query($slug: String) {
        contentfulLegal(slug: { eq: $slug }) {
            title
            metadescription
            content {
                json
            }
        }
        site {
            siteMetadata {
                company_name
                web_full_name
                web_mail
            }
        }
    }
`
